<template>
	<v-container
		fill-height
        fluid>
		<v-row
			align="center"
			justify="center">
			<v-col
				cols="11"
				md="auto">
				<v-card color="transparent"
					elevation="0"
					max-width="50em">
					<h1 class="display-1 font-weight-black font-italic secondary--text my-10">Відновлення забутого паролю</h1>
					<validation-observer
						ref="observer"
						v-slot="{ invalid, validated, handleSubmit }">
						<v-form @submit.prevent="handleSubmit(submit)">
							<v-row>
								<v-col
									cols="12"
									class="py-0">
									<v-text-field-validatable
										v-model="user.email"
										name="email"
										type="email"
										label="емейл"
										rules="required|email"
										hint="Введи свій емейл і ми надішлемо тобі посилання для скидання паролю"
										required>
									</v-text-field-validatable>
								</v-col>
								
							</v-row>

							<v-row class="mb-10 align-center">
								<v-col
									cols="12">
									<v-btn
										type="submit"
										color="btnBg"
										class="btnTxt--text"
										v-ripple="{ class: 'success--text' }"
										:loading="loading"
										:disabled="invalid">
										Надіслати листа
									</v-btn>
								</v-col>
							</v-row>
							
							<small class="grey--text mb-10">Згадали пароль? Тоді тобі сюди: <router-link :to="{ name: 'login' }">ВХІД</router-link></small>

						</v-form>
					</validation-observer>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import { mapActions } from 'vuex'

export default {
	data: () => ({
		user: {
			email: '',
			password: '',
		},
		loading: false,
	}),
	methods: {
		...mapActions('notification', [
			'notify',
		]),
		...mapActions('auth', [
			'sendResetLinkEmail',
		]),
		async submit() {
			try {
				this.loading = true
				await this.sendResetLinkEmail(this.user)
				// this.$router.replace({
				// 	name: 'home',
				// })
			} catch (error) {
				this.$refs.observer.setErrors(error?.response?.data?.errors)
			} finally {
				this.loading = false
			}
		},
	},
}
</script>